import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vant, { Toast } from 'vant'
import 'vant/lib/index.css'
import '@vant/touch-emulator'

Vue.config.productionTip = false
Vue.use(Vant)

// 设置全局路由守卫
// router.beforeEach((to, from, next) => {
//   if (!navigator.onLine) {
//     Toast.fail('网络不可用，请更换网络或联系网站管理员')
//     return // 阻止导航
//   }
//   next() // 继续导航
// })

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
