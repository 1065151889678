import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

// 路由表
const routes = [
  {
    path: '/',
    name: 'layout',
    redirect: '/home',
    component: () => import('@/views/Layout'),
    children: [
      { path: 'home', name: 'home', component: () => import('@/views/Home') },
      // { path: 'article', name: 'article', component: () => import('@/views/Article') }
      { path: 'article/:securityCode', name: 'article', component: () => import('@/views/Article'), props: true }
    ]
  }
]

const router = new VueRouter({
  routes
})

export default router
